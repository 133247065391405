
import { CommentBodyType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'CommentTypeSelection'
})
export default class extends Vue {
  @Prop() readonly value?: CommentBodyType

  get items (): { id: CommentBodyType, icon: string, color: string, tooltip: string, name: string }[] {
    return [
      {
        color: 'info',
        icon: 'fas-question',
        id: 'question',
        name: 'Question',
        tooltip: 'Ask questions from your team'
      },
      {
        color: 'error',
        icon: 'fas-thumbs-down',
        id: 'inaccurate',
        name: 'Inaccuracy',
        tooltip: 'Mark something as inaccurate\nIncreases inaccuracy score if not resolved'
      },
      {
        color: 'white',
        icon: 'fas-lightbulb',
        id: 'idea',
        name: 'Idea',
        tooltip: 'Add ideas and thoughts to your landscape'
      }
    ]
  }
}
